<template>
    <modal name="WarningAutopaymentModal" width="308px" height="auto" @closed="onExit">
      <div class="modal">
        <CloseSvg
            class="modal__close"
            @click.native="$modal.hide('PaymentSuccessModalNew')"
        />
        <p class="modal__text">Вы можете отключить автоплатеж в любое время.</p>
        <div class="modal__buttons">
          <button class="modal__button modal__button_confirm" @click="exit">ОК</button>
        </div>
      </div>
    </modal>
</template>

<script>
    import CloseSvg from "../../assets/vue-icons/Modal/CloseSvg";
    export default {
        name: "WarningAutopaymentModal",
      components: {CloseSvg},
      methods:{
            onExit(){
                this.$emit('exit')
            },

            exit(){
                this.$modal.hide('WarningAutopaymentModal')
            }
        }
    }
</script>

<style scoped lang="scss">
.modal{
  background: #FFFFFF;
  border: 1px solid #EBEDEE;
  border-radius: 4px;
  width: 308px;
  height: 160px;
  display: flex;
  flex-direction: column;

  &__close{
    margin-top: 8px;
    margin-left: 274px;
  }

  &__text{
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    margin-left: 23px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
    width: 262px;
  }

  &__buttons{
    margin-top: 2px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__button{
    margin-right: 20px;

    &_confirm{
      width: 60px;
      height: 40px;
    }

    &_cancel{
      width: 100px;
      height: 40px;
    }

    background: #F9F9F9;
    border: 1px solid #EBEDEE;
    border-radius: 6px;
    font-family: Roboto, "sans-serif";
    font-weight: 900;
    font-size: 14px;
    color: #69748C;
    letter-spacing: 0.93px;
    text-align: center;
    cursor: pointer;

    &:hover{
      background: #F2f2f2;
    }
  }
}
</style>
