<template>
  <div style="width:100%;height:auto;padding-bottom: 150px;overflow: scroll;-webkit-overflow-scrolling: touch;">
    <loader v-show="isLoading"></loader>
    <div class="payment">
      <PaymentTariffCard
          v-if="$windowWidth > 992 && profile._payStatus !== 1"
          :tariff="tariff"
          :selected-tariff="selectedTariff"
          :active-invoice="activeInvoice"
          @select="onSelectTariff"
          @pay="onSelectPeriod(activeInvoice)"
          class="payment__pay_desktop"
      />
      <div class="payment__info"
           :class="{'payment__info_without' : activeCard == null}">
        <PaymentCard
            v-if="activeCard != null"
            class="payment__card"
            :activeCard="activeCard"
            :isEnableRecurrent="isEnableRecurrent"
            @change="onChangeRecurrent"
            @edit="showActiveCard"
        />
        <div class="payment__card" v-else></div>
        <PaymentTariffCard
            v-if="$windowWidth <= 992 && profile._payStatus !== 1"
            :tariff="tariff"
            :selected-tariff="selectedTariff"
            :active-invoice="activeInvoice"
            @select="onSelectTariff"
            @pay="onSelectPeriod(activeInvoice)"
            class="payment__pay"
        />
        <div class="payment__invoices invoices">
          <div class="invoices__info">
            <p class="invoices__title">Счета</p>
            <p class="invoices__expired">Продлен до {{expiredDate}}</p>
          </div>
          <div class="invoices__list">
            <PaymentInvoiceItem
                class="invoices__list__item"
                v-for="invoice of paidInvoices"
                v-bind:key="invoice.id"
                :invoice="invoice"
                @download="downloadInvoice(invoice)"/>
          </div>
        </div>
      </div>
    </div>
    <EditCardModalNew/>
    <DeleteCardModalNew
        @delete="deleteCard"/>
    <DisconnectAutoPaymentModal
        @decline="declineChangesRecurrent"
        @enable="disableRecurrent"/>
    <ConnectAutoPaymentModal
        @enable="enableRecurrent"
        @decline="declineChangesRecurrent"/>
    <PaymentSuccessModalNew
        @exit="updatePage"/>
    <PaymentFailedModalNew
        @exit="updatePage"/>
    <WarningAutopaymentModal
        @exit="updatePage"/>
  </div>
</template>

<script>
import Loader from "@/components/loader/loader";
import moment from "moment";
import TariffCard from "../../components/card/TariffCard";
import PayCard from "../../components/card/PayCard";
import PaymentCard from "../../components/Payment/PaymentCard";
import PaymentTariffCard from "../../components/Payment/PaymentTariffCard";
import PaymentInvoiceItem from "../../components/Payment/PaymentInvoiceItem";
import EditCardModalNew from "../../components/modal/EditCardModalNew";
import DeleteCardModalNew from "../../components/modal/DeleteCardModalNew";
import DisconnectAutoPaymentModal from "../../components/modal/DisconnectAutoPaymentModal";
import ConnectAutoPaymentModal from "../../components/modal/ConnectAutoPaymentModal";
import PaymentSuccessModalNew from "../../components/modal/PaymentSuccessModalNew";
import PaymentFailedModalNew from "../../components/modal/PaymentFailedModalNew";
import WarningAutopaymentModal from "../../components/modal/WarningAutopaymentModal";
export default {
  name: "PayViewNew",
  components: {
    WarningAutopaymentModal,
    PaymentFailedModalNew,
    PaymentSuccessModalNew,
    ConnectAutoPaymentModal,
    DisconnectAutoPaymentModal,
    DeleteCardModalNew,
    EditCardModalNew,
    PaymentInvoiceItem,
    PaymentTariffCard,
    PaymentCard,
    PayCard,
    TariffCard,
    Loader,},
  data(){
    return {
      activeCard : null,
      activeInvoice : {price: 0},
      invoices : [],
      isEnableRecurrent: null,
      isLoading: true,
      selectedTariff: {},
    }
  },
  computed:{

    isMobile(){
      return screen.width < 992
    },
    paidInvoices(){
      return this.invoices.filter((invoice) => {
        return invoice.status
      }).reverse()
    },
    tariffs(){
      return this.$store.getters.TARIFFS
    },

    tariff(){
      const tariffs = this.tariffs;
      return tariffs[0]
    },

    expiredDate(){
      const profile = this.$store.getters.PROFILE
      return moment(profile._expiredAt.date).format("DD-MM-YYYY")
    },

    profile(){
      const profile = this.$store.getters.PROFILE;

      if (profile == null) return {}
      return profile
    }
  },
  methods:{
    showActiveCard(){
      this.$modal.show("EditCardModalNew",{value : this.activeCard})
    },


    async deleteCard(){
      const res = await this.$store.dispatch("DELETE_ACTIVE_CARD",{activeCard : this.activeCard})
      this.$router.go(0)
    },

    async onPaymentResult(res){
      console.log("res",res)
      if (res.success){
        this.$modal.show("PaymentSuccessModalNew");
      } else {
        this.$modal.show("PaymentFailedModalNew",{cardHolderMessage : res.cardHolderMessage})
      }
    },

    payWithCloudPayments(tariffId,periodId,sum,period,text){
      const profile = this.$store.getters.PROFILE

      var data = {};
      data.payApiInfo = {tariffId : tariffId,periodId : periodId}
      data.cloudPayments = {recurrent: { interval: 'Month', period: period}}; //создание подписки прямо сейчас

      //@ts-ignore
      var widget = new cp.CloudPayments();
      widget.charge({ // options
            publicId: 'pk_64f6cfc08ae1471599c948301321f',  //id из личного кабинета
            //description: 'Доступ к сервису видеонаблюдения SOVA по подписке', //назначение
            description: text,
            amount: sum, //сумма
            currency: 'RUB', //валюта
            skin: "modern", //дизайн виджета
            accountId: profile._id,
            data: data
          },
          (options) =>  { // success
            this.$modal.show("WarningAutopaymentModal")
          },
          (reason, options) => { // fail
            this.$router.go(0)
          });
    },

    async payWithApi(tariffId,periodId){
      if (!this.$store.getters.IS_PAY_PROCESS){
        this.$store.commit("SET_IS_PAY_PROCESS",true)
        const res = await this.$store.dispatch("PAY",{isEnableRecurrent : this.isEnableRecurrent,tariffId: tariffId,periodId : periodId })
        await this.onPaymentResult(res)
      }
    },


    onSelectTariff(tariffId,periodId){

      const tariff = this.tariffs.filter(tariff => tariff.id === tariffId)[0]
      const period = tariff.tariffPeriods.filter(period => period.id === periodId)[0]



      if (this.selectedTariff.id === periodId){
        TweenLite.to(".tariff__pay",.3,{ height: 0,onComplete : () => {
            this.selectedTariff = {}
            this.activeInvoice.price = 0
          }}).delay(0.2).play()
      } else {
        this.selectedTariff = {...period}
        const priceSum = (tariff.price * period.periodMonth) - period.discount
        let text = `Доступ к сервису видеонаблюдения SOVA по подписке ${period.description}`
        if (this.activeInvoice.price === 0){
          TweenLite.from(".tariff__pay",.3,{height: 0}).delay(0.2).play()
        }
        this.activeInvoice.tariffId = tariffId
        this.activeInvoice.periodId = periodId
        this.activeInvoice.text = text
        this.activeInvoice.price = priceSum
        TweenLite.to(".tariff__pay",.3,{height: 96}).delay(0.2).play()

      }
    },

    async onChangeRecurrent(checked){
      console.log(checked)
      console.log({
        enablerreccuent : this.isEnableRecurrent,
        checked : checked
      })
      if (this.isEnableRecurrent != null && this.isEnableRecurrent !== checked && checked != null){
        await this.$store.dispatch("GET_PROFILE")
        const profile = this.$store.getters.PROFILE
        if (this.activeCard != null){
          if (!this.isEnableRecurrent){
            await this.enableRecurrent()
          } else {
            this.$modal.show("DisconnectAutoPaymentModal",{user:profile})
          }
        }
        this.isEnableRecurrent = checked

      }
    },

    declineChangesRecurrent(){
      const profile = this.$store.getters.PROFILE
      this.activeCard = {...profile._activeCard}
      this.isEnableRecurrent = this.activeCard.isEnableRecurrent
    },

    updatePage(){
      this.isLoading = true
      setTimeout(async () => {
        await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
        await this.init()
      },5000)
    },

    async enableRecurrent(){
      await this.$store.dispatch("CHANGE_RECURRENT",{isEnableRecurrent : true})
    },

    async disableRecurrent(){
      await this.$store.dispatch("CHANGE_RECURRENT",{isEnableRecurrent : this.isEnableRecurrent})
    },

    async downloadInvoice(invoice){
      const response = await this.$store.dispatch("DOWNLOAD_INVOICE",{id : invoice.id})
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `file.csv`)
      document.body.appendChild(link)
      link.click()
    },

    async init(){
      await this.$store.dispatch("GET_PROFILE")
      await this.$store.dispatch("GET_TARIFFS")
      const profile = this.$store.getters.PROFILE
      this.isLoading = true
      this.activeCard = (profile._activeCard != null ) ? {...profile._activeCard} : null
      this.invoices = [...profile.invoices]
      this.activeInvoice = this.invoices.filter((invoice) => !invoice.status)[0]

      if (this.activeCard != null){
        this.isEnableRecurrent = this.activeCard.isEnableRecurrent
      }

      // console.log(this.tariffs)
      this.isLoading = false

      this.$nextTick(() => {
        if (this.tariff.tariffPeriods.length === 1){
          const tariff = this.tariffs[0];
          this.onSelectTariff(tariff.id,tariff.tariffPeriods[0].id)
        }
      })
    },

    onRefresh(){
      this.$router.go(0)
    },


    onSelectPeriod(activeInvoice){
      const tariff = this.tariffs.filter(tariff => tariff.id === activeInvoice.tariffId)[0]
      const period = tariff.tariffPeriods.filter(period => period.id === activeInvoice.periodId)[0]

      const priceSum = (tariff.price * period.periodMonth) - period.discount
      let text = `Доступ к сервису видеонаблюдения SOVA по подписке ${period.description}`


      if (this.activeCard != null){
        this.payWithApi(tariff.id,period.id)
      } else {
        this.payWithCloudPayments(tariff.id,period.id,priceSum,period.periodMonth,text)
      }
    }
  },


  async mounted(){
    await this.init()
  },


}
</script>

<style scoped lang="scss">
.payment{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;

  &__info{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
    justify-content: center;
    @include media(992){
      flex-direction: column;
      align-items: center;

      &_without{
        margin-top: 0;
      }
    }
  }

  &__card{
    margin-left: 65px;
    width: 300px;
    @include media(992){
      margin-left: 0;

    }
  }
  &__invoices{
    @include media(992){
      margin-top: 20px;
    }
  }

  &__pay{
    margin-top: 15px;

    &_desktop{
      margin-top: 0;
    }
  }
}

.invoices{
  &__info{
    display: flex;
    justify-content: space-between;
    width: 335px;
    height: 47px;
    border-bottom: 1px solid #EBEDEE;

    @include media(992){
      width: 500px;
    }

    @include media(767){
      width: 335px;
    }
  }

  &__title{
    font-family: Roboto, "sans-serif";
    font-weight: 400;
    line-height: 33px;
    font-size: 28px;
    color: #222222;
  }

  &__expired{
    font-family: Roboto, "sans-serif";
    font-weight: 400;
    font-size: 12px;
    color: #222222;
    letter-spacing: 0;
    text-align: right;
    line-height: 14px;
    width: 180px;
    margin-top: 15px;
  }

  &__list{
    margin-top: 20px;

    @include media(767){
      margin-top: 10px;
    }
    &__item{
      margin-bottom: 20px;

      @include media(767){
        margin-top: 10px;
      }
    }
  }


}
</style>
