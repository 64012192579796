<template>
  <div class="invoice" :class="{'invoice_active' : !invoice.status}">
    <div class="invoice__header">
      <p class="invoice__status">{{!invoice.status ? 'К оплате' : 'Оплачено'}}</p>
      <p class="invoice__sum">{{invoice.price}}.00 ₽</p>
    </div>
    <p class="invoice__date">{{(invoice.status) ? invoice.date : ""}}</p>
    <div class="invoice__container" :class="{'invoice__container_active' : !invoice.status}">
      <div class="invoice__classes">
        <ClassIcon
          class="invoice__class"
          v-for="cl of invoice.classes"
          v-bind:key=cl.id
          :text="cl.fullName"/>
      </div>
      <div class="invoice__buttons">
        <p
            class="invoice__month"
            :class="{'invoice__month_demo' : invoice.price === 0}"
            v-if="invoice.status && invoice.price > 0 && invoice.monthCount > 0">{{invoice.monthCount+' мес'}}</p>
        <p
            class="invoice__month invoice__month_demo"
            v-if="invoice.status && invoice.price === 0">демо</p>
        <InvoiceSvg
            v-if="invoice.status"
            @click.native="$emit('download')"
            class="invoice__img"/>
        <ButtonPink
            v-if="!invoice.status"
            @click.native="$emit('pay')"
            size="xs"
            :disabled="$store.getters.IS_PAY_PROCESS"
            value="ОПЛАТИТЬ"/>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceSvg from "../../assets/vue-icons/Tariff/InvoiceSvg";
import ButtonPink from "../button/ButtonPink";
import ClassIcon from "../../assets/vue-icons/Class/ClassIcon";
export default {
  name: "PaymentInvoiceItem",
  components: {ClassIcon, ButtonPink, InvoiceSvg},
  props:{
    invoice: Object
  }
}
</script>

<style scoped lang="scss">
  .invoice{
    background: #FFFFFF;
    border: 1px solid #EBEDEE;
    border-radius: 8px;
    width: 335px;
    height: 96px;

    @include media(992){
      width: 500px;
    }

    @include media(767){
      width: 335px;
    }

    &_active{
      height: 0;
      overflow: hidden;
      width: 500px;

      @include media(767){
        width: 335px;
      }
    }


    &__header{
      display: flex;
      justify-content: space-between;
      margin-left: 9px;
      margin-right: 10px;
      margin-top: 8px;
    }

    &__status{
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      width: 160px;
      font-size: 17px;
      line-height: 20px;
      color: #333942;
      letter-spacing: 0;
    }

    &__sum{
      font-family: Roboto, "sans-serif";
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      width: 120px;
      color: #333942;
      letter-spacing: 0;
      text-align: right;
    }

    &__date{
      margin-top: 5px;
      margin-left: 9px;
      font-family: Roboto, "sans-serif";
      font-size: 10px;
      color: #909090;
      letter-spacing: 0;
      height: 10px;
    }

    &__container{
      display: flex;
      justify-content: space-between;
      margin-left: 12px;
      margin-right: 10px;
      margin-top: 9px;
      align-items: center;

      &_active{
        margin-top: 2px;
      }
    }

    &__classes{
      display: flex;
    }

    &__class{
      margin-right: 5px;
    }

    &__month{
      background: #745489;
      border-radius: 13px;
      width: 80px;
      height: 26px;
      font-family: CodeNext;
      font-weight: bold;
      line-height: 26px;
      font-size: 13px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      margin-right: 20px;

      &_demo{
        background: #F54E5E;
      }
    }

    &__img{
      margin-right: 14px;
    }

    &__buttons{
      display: flex;
    }
  }


  *{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
</style>
