<template>
<div class="card" v-if="activeCard != null">
    <PayCard
        :card="activeCard"
        @click.native="$emit('edit')"/>
    <div class="card__switch" v-if="$store.getters.PAY_STATUS">
      <p>АВТОПРОДЛЕНИЕ</p>
      <graphic-switch
      :checked="isEnableRecurrent" 
      @change="onChangeRecurrent"
      ></graphic-switch>
    </div>
  </div>
</template>

<script>
import PayCard from "../card/PayCard";
import GraphicSwitch from "../switch/GraphicSwitch";
export default {
  name: "PaymentCard",
  components: {GraphicSwitch, PayCard},
  props:{
    activeCard:{
      default(){
        return null
      }
    },
    isEnableRecurrent: {
      default(){
        return null
      }
    }
  },
  methods:{
    onChangeRecurrent(checked){
      this.$emit("change",checked)
    }
  }
}
</script>

<style scoped lang="scss">
  .card{
    &__switch{
      display: flex;
      justify-content: space-between;
      width: 300px;
      align-items: center;
      margin-top: 30px;

      p{
        font-family: CodeNext;
        font-weight: 800;
        font-size: 14px;
        color: #333942;
        letter-spacing: 0;
        line-height: 14px;
      }
    }
  }
</style>
