<template>
    <div class="tariff--card-outer">
        <div class="tariff--card">
            <div class="text--container" :class="{'months' : months > 1}">
                <p class="price__text">{{pricePerMonth.toFixed(0)}} ₽</p>
                <p class="month__text">в месяц</p>
                <p class="condition__text" v-show="months > 1">при оплате за {{months}} месяцев</p>
            </div>
            <img src="https://test-app.esova.ru/img/cached/iphone-image.60a286c1.png" v-if="months === 1" class="iphone__image">
            <img src="https://test-app.esova.ru/img/cached/1classes.e5e5b96d.png" v-if="months === 1" class="classes1__image">
            <img src="https://test-app.esova.ru/img/cached/6classes.e5d5e619.png" v-if="months === 6" class="classes6__image">
            <img src="https://test-app.esova.ru/img/cached/9classes.6e7a1d8d.png" v-if="months === 9" class="classes9__image">
            <div class="classes--container-text" v-if="classesCount > 1" :class="{'org-g' : classesCount === 2, 'pur-g' : classesCount === 3, 'gre-g' : classesCount === 4}">
                <p>{{classesCount}} ученика</p>
            </div>
            <img src="https://test-app.esova.ru/img/cached/15.d475eda3.svg" v-if="months === 6" class="discount-img">
            <img src="https://test-app.esova.ru/img/cached/20.d475eda3.svg" v-if="months === 9" class="discount-img">

        </div>
    </div>
</template>

<script>
    export default {
        name: "TariffCard",
        props: {
            pricePerMonth : Number,
            idTariff : Number,
            months : Number,
            classesCount : Number
        }
    }
</script>

<style scoped lang="scss">
    .tariff--card{
        border-radius: 4px;
        background-image: linear-gradient(-122deg, #FFFFFF 0%, #F0EFEF 96%);
        display: flex;
        flex-direction: column;
        position: relative;
        width: 116px;
        height: 186px;

        .text--container{
            margin-left: auto;
            margin-right: auto;
            width: 66px;
            height: 24px;
            margin-top: 17px;


            .price__text{
                font-family: Roboto, "sans-serif";
                font-weight: 400;
                color: #4a4a4a;
                text-align: left;

                font-size: 14px;
                letter-spacing: 0.09px;
                line-height: 16px;
            }

            .month__text{
                font-family: Roboto, "sans-serif";
                font-weight: 300;
                color: #4a4a4a;
                text-align: right;

                font-size: 9px;
                letter-spacing: 0px;
                line-height: 10px;
            }

            .condition__text{
                font-family: Roboto, "sans-serif";
                font-weight: 300;
                color: #4a4a4a;
                text-align: center;

                font-size: 7px;
                letter-spacing: 0px;
                line-height: 8px;
            }


            &.months{

                width: 80px;
                height: 32px;

                .price__text{
                    margin-left: 18px;
                }
            }
        }

        .iphone__image{
            position: absolute;

            width: 59px;
            height: 117px;
            top: 59px;
            left: 29px;
        }

        .classes1__image{
            position: absolute;

            width: 116px;
            height: 66px;
            top: 121px;
            left: 0px;
        }

        .classes6__image{
            position: absolute;

            width: 116px;
            height: 90px;
            top: 97px;
            left: 0px;
        }


        .classes9__image{
            position: absolute;

            width: 116px;
            height: 115px;
            top: 73px;
            left: 0px;
        }


        .classes--container-text{
            position: absolute;
            bottom: -2px;
            left: 0;
            border-radius: 0 0 4px 4px;
            opacity: 0.9;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
            display: flex;
            align-items: center;
            justify-content: center;

            width: 118px;
            height: 30px;

            &.org-g{
                background-image: linear-gradient(-173deg, #FAD961 0%, #F76B1C 100%);
            }

            &.pur-g{
                background-image: linear-gradient(3deg, #3023AE 0%, #C86DD7 100%);
            }

            &.gre-g{
                background-image: linear-gradient(-180deg, #B4EC51 0%, #429321 100%);
            }

            p{
                font-family: Roboto, "sans-serif";
                font-weight: 500;
                font-size: 11px;
                color: white;
                font-size: 10px;

            }
        }


        .discount-img{
            position: absolute;
            left: 0px;
            top: 0;
            width: 44px;

        }

        .tariff--card-outer{
            border-radius: 4px;
        }



    }


    *{
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    }
</style>
