<template>
  <div class="tariff" :class="{'tariff_simple' : tariff.tariffPeriods.length === 1}">
    <div class="tariff__form" v-if="tariff.tariffPeriods.length > 1">
      <p class="tariff__title" :class="{'tariff__title_selected' : activeInvoice != null && activeInvoice.price > 0}">ВЫБЕРИТЕ ТАРИФ</p>
      <div class="tariff__container">
        <tariff-card v-for="tariffPeriod in tariff.tariffPeriods"
                     v-bind:key="tariffPeriod.id"
                     class="tariff__card"
                     :classes-count="tariff.classesCount"
                     :months="tariffPeriod.periodMonth"
                     :price-per-month="getPricePerMonth(tariff,tariffPeriod)"
                     :id-tariff="tariffPeriod.id"
                     @click.native="onSelectTariff(tariff.id,tariffPeriod.id)"
                     :class="{'tariff__card_selected' : selectedTariff.id == tariffPeriod.id}"
        ></tariff-card>
      </div>
      <p class="tariff__text" v-show="activeInvoice == null || activeInvoice.price === 0">Период каникул не оплачивается</p>
      <PaymentInvoiceItem
          v-show="activeInvoice != null && activeInvoice.price > 0"
          class="tariff__pay"
        :invoice="activeInvoice"
        @pay="$emit('pay')"/>
    </div>
    <PaymentInvoiceItem
        v-else
        class="tariff__pay"
        :invoice="activeInvoice"
        @pay="$emit('pay')"/>
  </div>
</template>

<script>
import TariffCard from "../card/TariffCard";
import PaymentInvoiceItem from "./PaymentInvoiceItem";
export default {
  name: "PaymentTariffCard",
  components: {PaymentInvoiceItem, TariffCard},
  props: {
    tariff : Object,
    selectedTariff: Object,
    activeInvoice: Object
  },
  methods:{
    onSelectTariff(tariffId,tariffPeriodId){
      this.$emit("select",tariffId,tariffPeriodId)
    },

    getPricePerMonth(tariff,tariffPeriod){
      if (tariffPeriod.id === 4) return 275
      else if (tariffPeriod.id === 6) return 245;
      else return tariff.price - (tariffPeriod.discount/tariffPeriod.periodMonth)
    },
  }
}
</script>

<style scoped lang="scss">
  .tariff{
    display: flex;
    width: 100%;
    justify-content: center;
    height: auto;
    min-height: 332px;
    padding-bottom: 14px;
    background: rgba(34,34,34,0.05);



    &_simple{
      background: transparent;
      margin-top: 30px !important;
      margin-bottom: 30px;
      min-height: 0px;
      @include media(992){
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }


    &__form{
      margin-top: 40px;
      display: flex;
      flex-direction: column;
    }

    &__title{
      font-family: CodeNext;
      font-weight: bold;
      font-size: 14px;
      color: #323841;
      letter-spacing: 0;
      line-height: 14px;

      &_selected{
        margin-left: 56px;

        @include media(767){
          margin-left: 0px;
        }
      }
    }

    &__container{
      display: flex;
      margin-top: 20px;
      justify-content: center;
    }

    &__pay{
      margin-top: 20px;

      @include media(767){
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__text{
      font-family: Golca;
      font-weight: 100;
      margin-top: 24px;
      font-size: 14px;
      color: #333942;
      letter-spacing: 0.31px;
      text-align: center;
      line-height: 14px;

    }

    &__card{
      margin-right: 20px;
      transition: all .2s ease-in-out;
      cursor: pointer;
      overflow: hidden;
      transform: scale(1);
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.29) ;
      border-radius: 4px;

      @include media(767){
        margin-right: 6px;
      }

      &:hover {
        transform: scale(1.01);

      }

      &:last-child{
        margin-right: 0;
      }

      &_selected{
        transform: scale(0.96);
        box-shadow: 0 0 6px 0 rgba(0,0,0,0.4) ;



        &.hover{
          transform: scale(0.96);
        }
      }
    }
  }
</style>
