<template>
    <label class="switch">
        <input type="checkbox" v-model="mutChecked">
        <span class="slide round green"><span class="shadow round"><span class="green"></span></span></span>
    </label>
</template>

<script lang="js">
    export default {
        name:"GraphicSwitch",
        props:{
            checked: Boolean
        },
        data(){
            return {
                mutChecked : false
            }
        },
        watch:{
            checked:{
                deep: true,
                immediate: true,
                handler: function(checked) {
                    this.mutChecked = checked
                }
            },
            mutChecked: {
                deep: true,
                immediate: true,
                handler: function(newval){
                    this.$emit("input",newval)
                    this.$emit("change",newval)
                }
            }
        }
    }
</script>

<style scoped lang="scss">



    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 51px !important;
        height: 25px !important;
        border: 1px solid #EBEDEE;
        border-radius: 18px;
    }

    .switch.lg{
        width: 51px !important;
        height: 25px !important;

        @include for-phone-only{
            width: 50px !important;
            height: 25px !important;
        }

        @include for-tablet-up{
            width: 50px !important;
            height: 25px !important;
        }
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slide {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        -webkit-transition: .4s;
        transition: .4s;
    }



    .slide:before {
        position: absolute;
        content: "";
        height: 25px;
        width: 25px;
        left: -1px;
        bottom: -1px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border: 1px solid #CECECE;
        border-radius: 50%;
    }

    .slide.lg:before{
        height: 17px;
        width: 17px;


        @include for-phone-only{
            width: 25px;
            height: 25px;
        }

        @include for-tablet-up{
            width: 25px;
            height: 25px;
        }

    }

    input:checked + .slide {
        background-color: #FFB450;
    }

    input:checked + .yellow {
        background-color: #FFB450;
    }

    input:checked + .green {
        background-color: #B8E986;
    }


    input:checked + .slide:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slide.round {
        border-radius: 18px;
    }

    .slide.round:before {
        border-radius: 50%;
    }

    input:disabled{
        pointer-events: none;
    }

    .switch.disabled{
        pointer-events: none;
        opacity: 0.5;
    }
</style>
